<script>
import { required } from "vuelidate/lib/validators";
import DatePicker from "vue2-datepicker";

import Layout from "../../../layouts/main";
import PageHeader from "@/components/page-header";

import PatientService from "@/services/patient-service";
import PaymentTypeService from "@/services/paymenttype-service";
/**
 * Patients Component
 */
export default {
  components: {
    Layout,
    PageHeader,
    DatePicker,
  },
  data() {
    return {
      pacientes: {},
      title: this.$t("all.patients"),
      items: [
        {
          text: this.$t("all.admin"),
        },
        {
          text: this.$t("all.patients"),
          active: true,
        },
      ],
      patientsData: [],
      patient: {},
      submitted: false,
      showmodal: false,
      paymentTypes: {},

      //Paginacao
      pagination: {
        numberOfElements: 0,
        totalPages: 0,
        totalElements: 0,
        last: true,
        first: true,
        number: 0,
        size: 10,
      },
      paginate: false,

      params: {
        size: 10,
        sort: "name",
        active: true, 
      },

      filters: {

        name: "",


      },
    };
  },

  validations: {
    patient: {
      name: { required },
      birth: { required },
      paymentType: { required },
    },
  },

  created() {
    this.service = new PatientService(this.$http);
    this.paymentTypeService = new PaymentTypeService(this.$http);
    this.charge();
    this.chargePaymentTypes();
  },
  methods: {
    age(birthDate) {
      let now = new Date();

      birthDate = new Date(Date.parse(birthDate));

      var years = now.getFullYear() - birthDate.getFullYear();

      var months = now.getMonth() - birthDate.getMonth();
      var days = now.getDate() - birthDate.getDate();

      if (months < 0 || (months == 0 && days < 0)) {
        years--;
        months += days < 0 ? 11 : 12;
      }

      if (days < 0) {
        let monthAgo = new Date(
          now.getFullYear(),
          now.getMonth() - 1,
          birthDate.getDate()
        );
        days = Math.floor((now - monthAgo) / (1000 * 60 * 60 * 24));
      }

      return years + "a" + months + "m" + days + "d";
    },
    charge() {
      
      this.loading = true;
      this.service.list(this.params).then((res) => {
        console.log(res);
        this.patientsData = res.data.content;
        this.pagination.totalPages = res.data.totalPages;
        this.pagination.totalElements = res.data.totalElements;
        this.pagination.last = res.data.last;
        this.pagination.first = res.data.first;
        this.pagination.number = res.data.number;
        this.pagination.size = res.data.size;
        this.pagination.numberOfElements = res.data.numberOfElements;

        this.paginate = res.data.totalPages > 0;
        this.loading = false;
      }),
        (err) => {
          console.error(err);
        };
    },
    chargePaymentTypes() {
      console.log("chargePaymentTypes")
      this.paymentTypeService.list({ size: 100 }).then((res) => {

        console.log("listPaymenttypes", res);
        this.paymentTypes = res.data.content;
        console.log(this.paymentTypes);
      }),
        (err) => {
          console.error(err);
        };
    },
    changeSize() {

      this.loading=true;
      this.params.page = 0;
      this.charge();
    },
    changeInactive(){
      this.loading=true;
      this.charge();
    },
    gotoPage(pageNum) {
      this.loading=true;
      this.params.page = pageNum;
      if (this.search && this.search.length > 2) {
        this.find();
      } else {
        this.charge();
      }
    },
    showPaymentType(paymentType) {
      if (paymentType) {
        return paymentType.name + " - (" + paymentType.type + ")"
      } else {
        return "";
      }
    },
    editDetail(patient) {
      this.$router.push(`/admin/patient/detail?patientId=${patient.id}`)
    },
    edit(patient) {
      let date = new Date(Date.parse(patient.birth));
      patient.birth = date;
      this.patient = patient;
      this.showmodal = true;
    },
    newComplete() {
      this.$router.push(`/admin/patient/detail`)
    },
    /**
     * Modal form submit
     */
    // eslint-disable-next-line no-unused-vars
    handleSubmit(e) {
      this.submitted = true;

      // stop here if form is invalid
      this.$v.$touch();
      if (this.$v.$invalid) {
        return;
      } else {
        const id = this.patient.id;
        const name = this.patient.name;
        const anotations = this.patient.anotations;
        const birth = this.patient.birth;
        const paymentType = this.patient.paymentType;

        this.service
          .save({
            id,
            name,
            birth,
            anotations,
            paymentType,
          })
          .then((ret) => {
            console.log(ret);
            this.charge();
            this.showmodal = false;
          });
      }
      this.submitted = false;
      this.patient = {};
    },
    /**
     * hode mondal on close
     */
    // eslint-disable-next-line no-unused-vars
    hideModal(e) {
      this.submitted = false;
      this.showmodal = false;
      this.patient = {};
    },

    /**
     * Filter the data of search
     */
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    filtrar() {
      this.loading=true;
      this.params.name = this.filters.name;
      this.params.paymentType = this.filters.paymentType;


      this.params.page = 0
      this.charge();
    },
  },
};
</script>

<template>
  <Layout>
    <PageHeader :title="$t('all.patients')" :items="items" />
    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-header">
            <b-card no-body class="mb-1">
              <b-card-header header-tag="header" role="tab">
                <h6 class="m-0">
                  <a v-b-toggle.accordion-3 class="text-dark" href="javascript: void(0);">{{ $t("all.filters") }} <i
                      class="mdi mdi-filter-menu mr-2"></i></a>
                </h6>
              </b-card-header>
              <b-collapse id="accordion-3" accordion="my-accordion" role="tabpanel" visible>
                <b-card-body >

                  <div class="form-group">
                    <div class="row">
                      <div class="col-md-5">
                      <label for="name">Paciente</label>
                      <input id="name" v-model="filters.name" type="text" class="form-control"
                        placeholder="Digite o nome" autocomplete="off" :class="{
      'is-invalid': submitted && $v.event.title.$error,
    }" />

                    </div>
                    <div class="text-right pt-2 mt-3">
                      <b-button type="button" @click="filtrar" variant="success" class="ml-1">Filtrar</b-button>
                    </div>
                    </div>


                    
                  </div>
                  <div class="row">
                    <div class="form-group col-md-5">
                      <label for="name">Ocultar Inativos?</label>
                      <b-checkbox id="active" checked="true" @change="changeInactive" v-model="params.active">
                      </b-checkbox>
                    </div>
                  </div>
                </b-card-body>
              </b-collapse>
            </b-card>
          </div>
          <div class="card-body">
            <div>
              <div v-if="loading" class="d-flex justify-content-center"></div>
              <!-- <a href="javascript:void(0);" class="btn btn-success mb-2 mr-2" @click="showmodal = true">
                <i class="mdi mdi-plus mr-2"></i> {{ $t("all.new") }}
              </a> -->

              <a href="javascript:void(0);" class="btn btn-success mb-2" @click="newComplete">
                <i class="mdi mdi-plus mr-2"></i> {{ $t("all.new") }}
              </a>
            </div>
            <div class="table-responsive mt-3">
              <table class="table table-centered datatable dt-responsive nowrap" style="
                  border-collapse: collapse;
                  border-spacing: 0;
                  width: 100%;
                ">
                <thead class="thead-light">
                  <tr>
                    <th>{{ $t("all.name") }}</th>
                    <th>{{ $t("all.birth") }}</th>
                    <th>{{ $t("all.paymentType") }}</th>
                    <th>{{ $t("all.annotations") }}</th>
                    <th>{{ $t("all.status") }}</th>
                    <th>{{ $t("all.isABA") }}</th>
                    <th style="width: 120px">{{ $t("all.actions") }}</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(item, index) in patientsData" :key="index">

                    <td>
                      <span v-if="!item.address || !item.address.address" class="text-warning"><b-tooltip
                          :target='"tooltip" + item.id'>Falta Endereço</b-tooltip><i :id='"tooltip" + item.id'
                          class="ri-alert-line"></i></span> {{ item.name }}
                    </td>
                    <td>
                      {{ item.birth | formatDate }} <br> {{ age(item.birth) }}
                    </td>
                    <td>{{ showPaymentType(item.paymentType) }}</td>

                    <td>{{ item.anotations }}</td>
                    <td>{{ $t("active." + item.active) }}</td>
                    <td>{{ $t("isABA." + item.aba) }}</td>
                    <td>
                      <a href="javascript:void(0);" class="mr-3 text-primary" v-b-tooltip.hover title="Edit"
                        @click="editDetail(item)">
                        <i class="mdi mdi-pencil font-size-18"></i>
                      </a>
                    </td>
                  </tr>
                </tbody>
              </table>
              <b-card-footer v-if="paginate" class="dataTables_wrapper dt-bootstrap4 no-footer">
                <div class="row">
                  <div class="col-md-11">
                    <ul class="pagination justify-content-left">
                      <li class="page-item">
                        <a v-if="!pagination.first" type="button" class="page-link btn-dark waves-effect waves-light"
                          @click="gotoPage(pagination.number - 1)">Anterior</a>
                      </li>
                      <li class="page-item">
                        <a v-for="num in pagination.totalPages" :key="num"
                          :class="num - 1 === pagination.number ? 'active' : ''" class="page-link btn-dark waves-effect"
                          @click="gotoPage(num - 1)">{{ num }}</a>
                      </li>
                      <li class="page-item">
                        <a v-if="!pagination.last" type="button" class="page-link btn-dark waves-effect waves-light"
                          @click="gotoPage(pagination.number + 1)">Próxima</a>
                      </li>
                    </ul>
                  </div>
                  <div class="col-md-1 justify-content-left">
                    <select v-model="params.size" class="form-control" @change="changeSize">
                      <option class="selected" selected>10</option>
                      <option>20</option>
                      <option>50</option>
                      <option>100</option>
                    </select>
                  </div>
                </div>
              </b-card-footer>
            </div>
          </div>
        </div>
      </div>
    </div>
     <!-- Modal Loading-->
     
     
     <b-modal id="modal-loadin" class="modal-dialog modal-sm" :hide-footer="true" v-model="loading">
      <b-spinner style="width: 3rem; height: 3rem;"  class="m-2 spinner-border text-primary"><!----></b-spinner>
      Aguarde

    </b-modal>
    <!-- Modal -->
    <b-modal id="modal-1" v-model="showmodal" :title="$t('all.patient')" title-class="text-dark font-18" hide-footer>
      <form @submit.prevent="handleSubmit">
        <input id="id" type="hidden" v-model="patient.id" />
        <div class="form-group">
          <label for="name">{{ $t("all.name") }}</label>
          <input id="name" v-model="patient.name" type="text" class="form-control" placeholder="Enter name"
            autocomplete="off" :class="{ 'is-invalid': submitted && $v.patient.name.$error }" />
          <div v-if="submitted && !$v.patient.name.required" class="invalid-feedback">
            Name is required
          </div>
        </div>
        <div class="form-group">
          <label for="exampleInputBirth1">{{ $t("all.birth") }}</label>
          <date-picker v-model="patient.birth" format="DD/MM/YYYY" value-type="date" type="date"
            :class="{ 'is-invalid': submitted && $v.patient.birth.$error }"></date-picker>
          <div v-if="submitted && $v.patient.birth.$error" class="invalid-feedback">
            <span v-if="!$v.patient.birth.required">Birth is required</span>
          </div>
        </div>
        <div class="form-group">
          <label for="exampleInputAnotations">{{
      $t("all.annotations")
    }}</label>
          <input id="anotations" v-model="patient.anotations" type="text" name="anotations" class="form-control"
            placeholder="Enter anotations" autocomplete="off" />
        </div>
        <div class="form-group">
          <label for="exampleInputAnotations">Pagamento</label>
          <select class="custom-select" v-model="patient.paymentType" :class="{
      'is-invalid': submitted && $v.patient.paymentType.$error,
    }">
            <option>Selecione forma de Pagamento</option>
            <option v-for="(payment, i) in paymentTypes" :key="i" :value="payment">
              {{ showPaymentType(payment) }}
            </option>
          </select>

          <div v-if="submitted && $v.patient.paymentType.$error" class="invalid-feedback">
            <span v-if="!$v.patient.paymentType.required">Pagamento is required</span>
          </div>
        </div>

        <div class="text-right">
          <button type="submit" class="btn btn-success">
            {{ $t("all.save") }}
          </button>
          <b-button class="ml-1" variant="danger" @click="hideModal">{{
      $t("all.cancel")
            }}</b-button>
        </div>
      </form>
    </b-modal>
    <!-- end modal -->
  </Layout>
</template>